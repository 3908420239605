var isRTL = ($('html').attr('dir') == "rtl") ? true : false,
    winWidth = $(window).width(),
    winHeight = $(window).height(),
    vh,
    hasTint = false;

mobLayoutFix();

if($('.js-tint').get(0)) {
    hasTint = true;
}

var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

if(isIE11) {
    $('html').addClass('is-ie-11');
    $('.js-intro-video track').remove();
}
docUnit();
$('.c-loading-bar .progress').stop().animate({'width': '50%'}, 6000);


$(function() {
    // browserDetect();
    setUpPopup();
    initSelect2();
    initGallery();
    fixTagList();
    initStickyFilterBar();
    innerNavSticky();
    onScrollNavInit();
    initMasonry();
    initShareBtn();
    initShowMore();
    window.$grid.masonry();
    socialIconAlone();
    articleListingAreaHeightCalc();
});

$(window).on('load', function() {
    // Do after Page ready
    tabletClicks();
    if(!$('html').hasClass('is-home')) {
        domReadyInnerPages(true);
    }
    setTimeout(function(){
        window.$grid.masonry();
    }, 400);
});

$(window).on('resize orientationchange', function() {
    // Do on resize
    if(winWidth != $(window).width()) {
        setTimeout(function(){
            window.$grid.masonry();
            setTimeout(function(){
                window.$grid.masonry();
            }, 400);
        }, 400);
    }
    winWidth = $(window).width(),
    winHeight = $(window).height();
    docUnit();
    calcStickyHeaderHeight();
    innerNavSticky();
    articleListingAreaHeightCalc();
});

$(window).on('scroll', function() {
    checkInview();
    if($(window).scrollTop() > 10) {
        $('html').addClass('not-on-top');
    }else{
        $('html').removeClass('not-on-top');
    }
});
window.onscroll = function(e) {
    if(this.oldScroll > this.scrollY) {
        $('html').removeClass('hide-footer');
    }else{
        $('html').addClass('hide-footer');
    }
    this.oldScroll = this.scrollY;
    
    if(($(window).scrollTop()+winHeight) > ($('body').height()-150)) {
        $('html').addClass('hide-footer');
    }
}

$(document).keyup(function(e) {
    if (e.keyCode == 27) {
        //Do on ESC press
        closePopup();
        closeSearch();
        closeMenu();

        $('.c-share-block').each(function(){
            $(this).removeClass('show--share');
        });
    }
});

function triggerPageLoader() {
    sortSrc();
    $('body').addClass('loader-start');
    ChangeToSvg();
    introVideoSetup();
}
function domReady() {
    if($('.has-loader').get(0)) {
        $('.c-loading-bar .progress').stop().animate({'width': '100%'}, function(){
            $('body').addClass('page-loaded');
            $('.c-loading-screen, .c-loading-bar').fadeOut(function(){
                document.querySelector('.js-intro-video').play();
                injectTint();
                document.querySelector('.js-intro-video').addEventListener('ended',myHandler,false);
                function myHandler(e) {
                    $('.js-skip-intro').click();
                }
            });
        });
    }
}
function domReadyInnerPages(force) {
    if(!$('html').hasClass('is-home')) {
        if (hasTint === true) {
            injectTint();
            return;
        }
        sortSrc();
        ChangeToSvg();
        $('.c-loading-bar .progress').stop().animate({'width': '100%'}, function(){
            $('html').addClass('inner-page-loaded');
            $('.c-loading-screen, .c-loading-bar').fadeOut(function(){
                showInPageMenu();
                showInPageMenuHome();
                checkInview();
            });
        });
    }
}
// function browserDetect() {
//     navigator.sayswho = (function() {
//         var ua = navigator.userAgent,
//             tem,
//             M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
//         if (/trident/i.test(M[1])) {
//             tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
//             return 'IE ' + (tem[1] || '');
//         }
//         if (M[1] === 'Chrome') {
//             tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
//             if (tem != null) return tem.slice(1).join('').replace('OPR', 'Opera');
//         }
//         M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
//         if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
//         return M.join(' ');
//     })();
//     $('body').addClass(navigator.sayswho);
// }
var tintIframeInterval;
function injectTint() {
    $('.js-tint').append('<script async src="https://cdn.hypemarks.com/pages/a5b5e5.js"></script><div class="tintup" data-id="cultureall" data-columns="" data-expand="true"    data-infinitescroll="true" data-personalization-id="1036748" style="height:500px;width:100%;"></div>');
    tintIframeInterval = setInterval(function(){
        if($('.js-tint iframe').get(0)){
            $('.js-tint iframe')[0].onload = function(){
                setTimeout(function(){
                    hasTint = 'loaded';
                    $('html').addClass('tint-loaded');
                    domReadyInnerPages(true);
                }, 700);
            }
            clearInterval(tintIframeInterval);
        }
    }, 500);
}
function tintReady() {
    console.log('.js-tint iframe');
    console.log($('.js-tint iframe'));
}
function introVideoSetup() {
    var video   = document.querySelector('.js-intro-video');

    if(!isIE11) {
        tracks  = video.textTracks[0],
        tracks.mode = 'hidden', // must occur before cues is retrieved
        cues    = tracks.cues;
    
        var replaceText = function(text) {
                $('.js-intro-video').parent().find('.text-track').html(text);
            },
    
            showText = function() {
                $('.js-intro-video').parent().find('.text-track').show();
            },
    
            hideText = function() {
                $('.js-intro-video').parent().find('.text-track').hide();
            },
    
            cueEnter = function() {
                replaceText(this.text);
                showText();
            },
    
            cueExit = function() {
                hideText();
            },
    
            videoLoaded = function(e) {
                for (var i in cues) {
                var cue = cues[i];
                cue.onenter = cueEnter;
                cue.onexit = cueExit;
                }
            };
        video.addEventListener('loadedmetadata', videoLoaded);
    }


    var playVideo = function(e) {
            domReady();
    },   
    onEnd = function() {
        skipIntro();
    };
    video.onloadstart = playVideo;
    video.onended = onEnd;
    video.load();

    $('.js-skip-intro').click(function(e){
        e.preventDefault();
        skipIntro();
    });

    $('.js-sound').click(function(e){
        e.preventDefault();
        var video = document.querySelector('.js-intro-video');
        if(video.muted == true) {
            $('.c-btn-sound').addClass('sound-on');
            video.muted = false;
        }else{
            $('.c-btn-sound').removeClass('sound-on');
            video.muted = true;
        }
    });
}

function skipIntro() {
    var video   = document.querySelector('.js-intro-video');
    video.pause();
    $('.c-video-intro').stop().fadeOut();
    $('html').addClass('intro-skipped');
    showInPageMenu();
}
function showInPageMenuHome(){
    setTimeout(function(){
        $('.c-page .js-is-menu-adv').addClass('reveal--now');
        setTimeout(function(){
            $('.c-page .js-is-menu-adv').removeClass('to-be-revealed');
            $('.c-page .js-is-menu-adv').removeClass('reveal--now');
        }, 1000);
    }, 500);
}
function showInPageMenu(){
    setTimeout(function(){
        $('.c-page .js-is-menu').addClass('reveal--now');
        setTimeout(function(){
            $('.c-page .js-is-menu').removeClass('to-be-revealed');
            $('.c-page .js-is-menu').removeClass('reveal--now');
        }, 1000);
    }, 500);
}
function ChangeToSvg() {
    $('img.tosvg').each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        $.get(imgURL, function (data) {
            var $svg = $(data).find('svg');
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }
            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');
            // Check if the viewport is set, else we gonna set it if we can.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
            }
            // Replace image with new SVG
            $img.replaceWith($svg);
        }, 'xml');
    });
}

function sortSrc() {
    if(winWidth < 768) {
        $('[data-mob-src]').each(function(){
            var srcVal = $(this).attr('data-mob-src');
            $(this).attr('src', srcVal);
        });
        $('[data-mob-bg]').each(function(){
            var srcVal = $(this).attr('data-mob-bg');
            $(this).css('background-image', 'url('+srcVal+')');
        });
    }else{
        $('[data-desktop-src]').each(function(){
            var srcVal = $(this).attr('data-desktop-src');
            $(this).attr('src', srcVal);
        });
        $('[data-desktop-bg]').each(function(){
            var srcVal = $(this).attr('data-desktop-bg');
            $(this).css('background-image', 'url('+srcVal+')');
        });
    }
}

function docUnit() {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

$('.js-toggle-search').click(function(e){
    e.preventDefault();
    toggleSearch();
});

function toggleSearch() {
    if($('html').hasClass('search-open')) {
        closeSearch();
    }else{
        closeMenu();
        $('html').addClass('search-open');
        $('.c-page').stop().fadeOut(function() {
            $('.c-search-panel').stop().fadeIn();
        });
    }
}
function closeSearch() {
    $('html').removeClass('search-open');
    $('.c-search-panel').stop().fadeOut(function() {
        $('.c-page').stop().fadeIn();
    });
}

$('.js-toggle-menu').click(function(e){
    e.preventDefault();
    toggleMenu();
});

var menuPanelTimeout;
function toggleMenu() {
    if($('html').hasClass('menu-open')) {
        closeMenu();
    }else{
        $('.c-menu.js-is-menu').addClass('to-be-revealed');
        clearTimeout(menuPanelTimeout);
        closeSearch();
        $('html').addClass('menu-open');
        $('.c-page').stop().fadeOut(function() {
            $('.c-menu-panel').stop().fadeIn(function(){
                if(winWidth < 768 && !$('.c-menu-panel .slick-track').width()) {
                    $('.c-menu-panel .c-menu').css('opacity', 0);
                    $('.c-menu-panel .c-menu').slick('refresh');
                    $('.c-menu-panel .c-menu').stop().animate({'opacity': 1});
                }
                $('.js-is-menu').addClass('reveal--now');
                menuPanelTimeout = setTimeout(function(){
                    $('.js-is-menu').removeClass('to-be-revealed');
                    $('.js-is-menu').removeClass('reveal--now');
                }, 1000);
            });
        });
    }
}

function closeMenu() {
    $('html').removeClass('menu-open');

    $('.c-menu-panel').stop().fadeOut(function() {
        $('.c-page').stop().fadeIn();
        $('.c-menu.js-is-menu').addClass('to-be-revealed');
    });
}

$('.js-video').each(function(){
    if(winWidth < 768) {
        var src = $(this).attr('data-m-src');
        var poster = $(this).attr('data-m-poster');
    }else{
        var src = $(this).attr('data-d-src');
        var poster = $(this).attr('data-d-poster');
    }
    if($(this).hasClass('is--youtube')){
        $(this).html(`<video poster="${poster}" preload="none" playsinline controls class="video-js" 
        data-setup='{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "${src}"}], "youtube": { "playsinline": 1, "showinfo": 0, "controls": 0, "autohide": 1 } }'>
        </video>`);
    }else{
        $(this).html(`<video preload="none" playsinline controls class="video-js" data-setup="{'width': '100%'}" poster="${poster}">
            <source src="${src}" type="video/mp4">
            <p class="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a
                web browser that
                <a href="https://videojs.com/html5-video-support/" target="_blank"
                    >supports HTML5 video</a
                >
            </p>
        </video>`);
    }
    var self = this;
    $('.video-js', this).ready(function(target) {
        $('.vjs-remaining-time:not(.shifted)', self).after($('.vjs-volume-panel', self));
        $('.vjs-remaining-time:not(.shifted)', self).addClass('shifted');
    });
});

$('.js-audio').each(function(i){
    var src = $(this).attr('data-src');
    $(this).html(`<audio id="audio-track-${i}" preload="none" playsinline controls class="video-js">
        <source src="${src}" type="audio/mpeg">
        <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank"
                >supports HTML5 video</a
            >
        </p>
    </video>`);

    var player = videojs('audio-track-' + i);
    var track = new videojs.AudioTrack({
        id: 'audio-track-' + i,
    });

    var self = document.querySelector('#audio-track-' + i);
    $(self).ready(function(target) {
        $('.vjs-remaining-time:not(.shifted)', self).after($('.vjs-volume-panel', self));
        $('.vjs-remaining-time:not(.shifted)', self).addClass('shifted');
    });
});

function setUpPopup() {
    $('.js-popup-link').click(function(e){
        e.preventDefault();
        var target = $(this).attr('href');
        openPopup(target);
    });
    $('.js-close-popup').click(function(e){
        e.preventDefault();
        closePopup();
    });
}
function openPopup(target, galleryIndex) {
    if($(target).find('.js-gallery-popup').get(0)) {
        $(target).find('.js-gallery-popup').slick('slickGoTo', galleryIndex);
    }
    var innerIframe = $(target).find('iframe[data-src]');
    if(innerIframe.get(0)){
        var iframeSrc = innerIframe.attr('data-src');
        innerIframe.attr('src', iframeSrc);
        innerIframe.removeAttr('data-src');
    }
    $('.c-popup').css({
        'height': '100%',
    });
    setTimeout(function(){
        $('html').addClass('popup-open');
        $(target).css({
            'height': '',
        });
        $(target).addClass('is--active');
    }, 100);
    // setTimeout(, 100);
}
function closePopup() {
    $('html').removeClass('popup-open');
    $('.c-popup .popup.is--active').removeClass('is--active');
    setTimeout(function() {
        $('.c-popup .popup').css({
            'height': '0',
        });
        setTimeout(function(){
            $('.c-popup').css({
                'height': '0',
            });
        }, 100);
    }, 400);
}

$('.js-animate-to').click(function(e){
    e.preventDefault();
    var target = $(this).attr('href');
    $("html, body").stop().animate({ scrollTop: $(target).offset().top - 50 }, 1000);
});

$('.js-field').each(function(){
    var self = this;
    
    if($('input, textarea', this)[0].value) {
        $(self).addClass("remove-label");
    }
    $('input, textarea', this).blur(function() {
        if(!this.value){
            $(self).removeClass("remove-label");
        }else{
            $(self).removeClass('has--error');
        }
    })
    .focus(function() {
        $(self).addClass("remove-label");
    });
    $('input, textarea', this).on('input', function(){
        if(this.value){
            $(self).addClass("remove-label");
            $(self).removeClass('has--error');
        }else{
            $(self).removeClass("remove-label");
        }
    });
});

// $('.js-search').click(function(){
//     var value = $(this).parent().find('input[type="text"]')[0].value;
//     console.log(value);
//     if(!value) {
//         $(this).parent().addClass('has--error');
//     }
// });

$('.js-open-view').click(function(e){
    e.preventDefault();
    var target = $(this).attr('href');
    console.log(target+' #map div.gm-style button[title="Toggle fullscreen view"]');
    $(target+' #map div.gm-style button[title="Toggle fullscreen view"]').trigger('click');
});

if(winWidth < 768) {
    $('.c-menu').slick({
        arrows: false,
        focusOnSelect: true,
        rtl: isRTL,
        // infinite: false,
    });
    // $('.js-mob-carousel').on('init', function(){
    //     $('.js-mob-carousel').slick('goto', 1);
    // });
    $('.js-mob-carousel').each(function(){
        if($('> *', this).length > 1){
            $(this).slick({
                arrows: false,
                focusOnSelect: true,
                rtl: isRTL,
                // infinite: false,
            });
        }
    });
}else{
    $('.js-carousel-1').each(function(){
        if($('> *', this).length > 3){
            $(this).slick({
                arrows: true,
                slidesToShow: 3,
                rtl: isRTL,
                responsive: [
                    {
                      breakpoint: 920,
                      settings: {
                        slidesToShow: 2,
                      }
                    },
                ]
            });
        }
    });
}
function initGallery() {
    $('.c-gallery').each(function(){
        var thisPopup = $(this).find('.js-gallery > a:first-child()').attr('href');
        $('.js-gallery', this).slick({
            arrows: false,
            slidesToShow: 5,
            swipeToSlide: true,
            rtl: isRTL,
            focusOnSelect: false,
            // asNavFor: '.js-gallery-popup',
            responsive: [
                {
                    breakpoint: 1450,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1150,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        focusOnSelect: true,
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
        if(winWidth > 767) {
            $('.js-gallery-popup .gallery-item .img').each(function(){
                var thisHtml = this.outerHTML;
                $(this).closest('.gallery-item').append(thisHtml);
                $(this).addClass('is--bg');
            });
        }
        $(thisPopup + ' .js-gallery-popup').slick({
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rtl: isRTL,
            // asNavFor: '.js-gallery',
            // responsive: [
            //     {
            //     breakpoint: 920,
            //     settings: {
            //         slidesToShow: 2,
            //     }
            //     },
            // ]
        });
        var galleryTop = $(this).offset().top;
        var galleryLeft = $(this).offset().left;
        var dragging = false;
        var draggignWait;
        $(this).mousemove(function(event){
            $('.cursor', this).css({
                "top": event.pageY - galleryTop,
                "left": event.pageX - galleryLeft,
                swipe:  function(){
                    if(winWidth > 1024) {
                        dragging = true;
                        clearTimeout(draggignWait);
                        draggignWait = setTimeout(function(){
                            dragging = false;
                        }, 100);
                    }
                },
                // beforeChange: function(){ dragging = true },
                // afterChange: function(){ 
                //     clearTimeout(draggignWait);
                //     draggignWait = setTimeout(function(){
                //         dragging = false
                //     }, 10);
                // },
            });
        });
        $('.js-popup-link-a', this).click(function(e){
            e.preventDefault();
            var thisIndex = $(this).closest('.slick-slide').attr('data-slick-index');
            if(winWidth > 1024) {
                if(dragging == false) {
                    var target = $(this).attr('href');
                    openPopup(target, thisIndex);
                }
            }else{
    
                if($(this).closest('.slick-slide').hasClass('slick-active')) {
                    var target = $(this).attr('href');
                    openPopup(target, thisIndex);
                }
            }
        });
        $(thisPopup + ' .js-toggle-content').click(function(e){
            e.preventDefault();
            if(winWidth > 767) {
                $(this).closest('.item-content').toggleClass('is--active');
            }
        });
    });
}

if(winWidth > 767) {
    $('[data-desktop-placeholder]').each(function(){
        $(this).attr('placeholder', $(this).attr('data-desktop-placeholder'));
    });
}else{
    $('[data-mob-placeholder]').each(function(){
        $(this).attr('placeholder', $(this).attr('data-mob-placeholder'));
    });
}

function Utils() {

}

Utils.prototype = {
    constructor: Utils,
    isElementInView: function (element, fullyInView) {
        var pageTop = $(window).scrollTop();
        var pageBottom = pageTop + winHeight - (winHeight*.1);
        var elementTop = $(element).offset().top;
        var elementBottom = elementTop + $(element).height();

        if (fullyInView === true) {
            return ((pageTop < elementTop) && (pageBottom > elementBottom));
        } else {
            return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
        }
    }
};

var _Utils = new Utils();

function checkInview() {
    $('.c-detail-page > *:not(:first-child), .c-audio-content .audio-panel').addClass('anim-in-view');
    var timeoutGap = 100;
    var timeoutGapRow = 2;
    $('.c-content-list .list-item:not(".in--view")').each(function(i){
        var self = this;
        if(i > timeoutGapRow){
            timeoutGap += 100;
            timeoutGapRow += 3;
        }
        setTimeout(function(){
            var isElementInView = _Utils.isElementInView($(self), false);
            if (isElementInView) {
                $(self).addClass('in--view');
            }
        }, timeoutGap);
    });
    $('.anim-in-view:not(".in--view")').each(function(){
        var isElementInView = _Utils.isElementInView($(this), false);
        if (isElementInView) {
            $(this).addClass('in--view');
        }
    });
    // if(winWidth > 767) {
    // }
}
function tabletClicks() {
    if($('html').hasClass('is--mob') && winWidth > 767) {
        // $('.c-menu .menu-item').click(function(e){
        //     console.log('here');
        //     $(this).find('.c-btn').click();
        // });
    }
}

function initSelect2() {
    $('.js-multi-select').each(function(){
        var thisSelectedLabel = $(this).attr('data-label');
        var placeholderText = $(this).attr('placeholder');
        var direction = 'ltr';
        if(isRTL) {
            direction = "rtl";
        }
        if(winWidth >= 1024) {
            $(this).select2({
                placeholder: placeholderText,
                width: '100%',
                closeOnSelect: false,
                dir: direction,
                dropdownParent: $(this).parent() 
            });
        }else{
            $(this).select2({
                placeholder: placeholderText,
                width: '100%',
                closeOnSelect: false,
                dir: direction,
            });
        }

        $(this).on('change', function(e) {
            console.log("this: " + this);
            if(typeof filterUpdated === "function"){
                filterUpdated($(this).val(), e.target);
            }
            let select = $(this),
                ul = select.next('span.select2').find('ul');
            // ul.find('.select2-selection__choice').remove();
            let count = select.select2('data').length;

            if(count > 0) {
                if(count == 1) {
                    ul.prepend(function() {
                        return "<li class='select2-selection__choice items-selected'>" + select.find('option:selected')[0].innerHTML + "</li>"
                    });
                }else{
                    ul.prepend(function() {
                        return "<li class='select2-selection__choice items-selected'>" + count + " " + thisSelectedLabel + "</li>"
                    });
                }
            }
            select2CheckIfHasSelection();
        });

        $(this).on('select2:open', function (e) {
            select2ClearBtn();
        });
    });
}

$('.js-filter-toggle').click(function(e){
    e.preventDefault();
    closeFilterBar();
});

function closeFilterBar() {
    $('select[data-select2-id]').each(function(){
        $(this).select2('close'); 
    });
    if($('body').hasClass('filter-is-open')){
        $('body').removeClass('filter-is-open');
        // $('.filter-bar-inner-wrap').stop().slideUp();
    }else{
        $('body').addClass('filter-is-open');
        // $('.filter-bar-inner-wrap').stop().slideDown();
    }
}

function articleListingAreaHeightCalc() {
    // var filterListingHeight = $('.article-listing').outerHeight();
    // var filterAreaHeight = (parseInt($('.c-wrap-sticky-filter').css('margin-bottom')) + $('.c-wrap-sticky-filter .filter-to-stick').outerHeight() + $('.c-wrap-sticky-filter + .container .c-search-result-txt__wrap').outerHeight() + parseInt($('.c-wrap-sticky-filter + .container .c-search-result-txt__wrap').css('margin-bottom'))) + parseInt($('.c-page').css('padding-bottom'));
    // if(winWidth < 1024) {
    //     filterAreaHeight = parseInt($('.c-search-result-txt__wrap').css('margin-bottom')) + parseInt($('.c-page').css('padding-bottom')) - ($('.c-search-result-txt').outerHeight()/2)
    // }
    // if(winWidth > 1023) {
    //     $('.article-listing').css('min-height', (winHeight - filterAreaHeight) + 80);
    // }else{
    //     $('.article-listing').css('min-height', (winHeight - filterAreaHeight));
    // }
    if(winWidth < 582) {
        $('.article-listing').css('min-height', (winHeight - 150));
    }else if(winWidth < 768) {
        $('.article-listing').css('min-height', (winHeight - 140));
    }else if(winWidth < 1024) {
        $('.article-listing').css('min-height', (winHeight - 200));
    }else if(winWidth < 1100) {
        $('.article-listing').css('min-height', (winHeight - 280));
    }else if(winWidth < 1400) {
        $('.article-listing').css('min-height', (winHeight - 270));
    }else{
        $('.article-listing').css('min-height', (winHeight - 260));
    }
}
// <div class="lds-dual-ring"></div>

function fixTagList() {
    $('.tags-list:not(.tags-list--perline)').each(function(){
        if($(this).height() > 26) {
            var maxWidth = $(this).width();
            var trackWidth = 0;
            var finalHTML = '';
            var remainingTags = [];
            $('.tag', this).each(function(i){
                trackWidth += $(this).width();
                if((trackWidth + 100) >= maxWidth){
                    remainingTags.push($('.tag-inner', this).html());
                }else{
                    finalHTML += '<div class="tag">' + $(this).html() + '</div>';
                }
            });

            if(remainingTags.length) {
                var titleList = '';
                remainingTags.forEach(function(val, x){
                    if(x != 0) {
                        titleList += ', ';
                    }
                    titleList += remainingTags[x];
                });
                if(isRTL) {
                    finalHTML += '<div class="tag tag--more"><div class="tag-inner" title="'+titleList+'">'+remainingTags.length+' المزيد ...</div></div>';
                }else{
                    finalHTML += '<div class="tag tag--more"><div class="tag-inner" title="'+titleList+'">'+remainingTags.length+' more ...</div></div>';
                }
            }
            this.innerHTML = finalHTML;
        }
        $('.tags-list').addClass('tags-list--perline')
    });
}
function initStickyFilterBar() {
    if($('.js-sticky-filter').get(0)){
        calcStickyHeaderHeight();
        $(window).scroll(function(){
            calcStickyFilterBar(true);
        });
        calcStickyFilterBar();
    }
}
function calcStickyHeaderHeight() {
    if(winWidth > 1023) {
        $('.js-sticky-filter').css('height', '60px');
    }else{
        $('.js-sticky-filter').css('height', '');
    }
    $('.c-search-result-txt__wrap').css('height', '54px');
}
var stickyBarTimeout;
function calcStickyFilterBar(calculate) {
    var topofDiv, height;
    
    topofDiv = $(".js-sticky-filter").offset().top; //gets offset of header
    height = $(".js-sticky-filter").outerHeight(); //gets height of header
    if(winWidth < 1024) {
        topofDiv = $(".c-search-result-txt__wrap").offset().top;
        height = $(".c-search-result-txt").outerHeight();
    }
    if($(window).scrollTop() > (topofDiv + height)){
        $("body").addClass('filter-bar-stick');
        stickyBarTimeout = setTimeout(function(){
            $("body").addClass('filter-bar-stick--anim-in');
        }, 20);
    }else{
        clearTimeout(stickyBarTimeout);
        $("body").removeClass('filter-bar-stick--anim-in');
        $("body").removeClass('filter-bar-stick');
    }
}

function innerNavSticky() {
    if($(".js-inner-nav").get(0)) {
        if(winWidth < 601) {
            $(".js-inner-nav").sticky({topSpacing:0});
        }else{
            $(".js-inner-nav").unstick();
        }
    }
    navBarMobLessDetector();
}

function navBarMobLessDetector() {
    $('.js-inner-nav').each(function() {
        console.log($('a', this).length)
        if($('a', this).length < 3){
            $(this).addClass('has--less');
        }else{
            $(this).removeClass('has--less');
        }
    });
}

function onScrollNavInit() {
    $('.js-inner-nav a').click(function(){
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top - 70
        }, 500);
        return false;
    });
    
    // Cache selectors
    var topMenu = $(".js-inner-nav"),
        topMenuHeight = topMenu.outerHeight()+15,
        // All list items
        menuItems = topMenu.find("a"),
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
          var item = $($(this).attr("href"));
          if (item.length) { return item; }
        });
    
    // Bind to scroll
    $(window).scroll(function(){
       // Get container scroll position
       var fromTop = $(this).scrollTop()+topMenuHeight;
    
       // Get id of current scroll item
       var cur = scrollItems.map(function(){
         if (($(this).offset().top - 70) < fromTop)
           return this;
       });
       // Get the id of the current element
       cur = cur[cur.length-1];
       var id = cur && cur.length ? cur[0].id : "";
       // Set/remove active class
       menuItems.removeClass("active");
       menuItems.filter("[href='#"+id+"']").addClass("active");
       if(!menuItems.filter(".active").get(0)) {
        $(".js-inner-nav a").eq(0).addClass('active');
       }
    });
}
function initMasonry() {
    window.$grid = $('.js-grid').masonry({
        itemSelector: '.c-art-block',
        originLeft: isRTL ? false : true,
        // columnWidth: 200
    });
}

function initShareBtn() {
    $('.js-share-btn').click(function(e){
        e.preventDefault();
        $(this).parent().toggleClass('show--share');
    });
    // outside click

    $('.c-share-block').each(function(){
        var self = this;
        var ignoreClickOnMeElement = self;
        document.addEventListener('click', function(event) {
            var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
            if (!isClickInsideElement) {
                $(self).removeClass('show--share');
            }
        });
    });
}

function initShowMore() {
    $('.js-show-more-btn').each(function() {
        var self = this;


        // Setup HTML
        if($(this).parent().hasClass('tib__txt-wrap') && winWidth < 768){
            var jumpIndex = 0;
            var lenIndex = 3;
            // if first para is too small
            if($(this).parent().find('p').eq(0).height() < 27) {
                // is single line
                jumpIndex = 1;
                lenIndex = 2;
            }
            var targetNthChild = ($('> p', $(this).parent()).eq(jumpIndex).index()+2);
            var animTarget = $('> *:nth-child(n+'+ targetNthChild +')', $(this).parent());
            if(animTarget.length < lenIndex) {
                $(this).remove();
                return;
            }
        }else{
            var liLimit = parseInt($(this).attr('data-li-limit'));
            var targetUl = $(' > ul', $(this).parent());
            var animTarget = $('> li:nth-child(n+'+(liLimit+1)+')', targetUl);
            if(animTarget.length < 1) {
                $(this).remove();
                return;
            }
        }
        console.log('animTarget.length: '+ animTarget.length);
        animTarget.stop().slideUp();

        // On click
        $(this).click(function(e){
            e.preventDefault();
            if($(self).hasClass('showing--more')){
                animTarget.stop().slideUp();
                $(self).removeClass('showing--more');
            }else{
                animTarget.slideDown();
                $(self).stop().addClass('showing--more');
            }
        });
    });
}

function mobLayoutFix() {
    if(winWidth < 768){
        $('.js-mob-send-bottom').each(function(){
            $(this).appendTo($(this).parent())
        });
    }
}

function socialIconAlone() {
    $('.cb3__list:not(.icon-html-updated-checked)').each(function(){
        var li = $('li', this);
        $(this).addClass('icon-html-updated-checked');
        if($('li', this).length == 1) {
            var link = $('li a', this);
            var value = link.attr('href');
            var splitVal = value.split('/')
            var name = splitVal[splitVal.length - 1];
            if(!name) {
                name = splitVal[splitVal.length - 2];
            }
            if(!name) {
                name = value;
            }
            li.append('<a href="'+ value +'" class="icon-text" target="_blank">'+ name +'</div>');
        }
    });
}

function select2ClearBtn() {
    $('.select2-results__options').each(function(){
        if(!$(this).closest('.select2-dropdown').find('.clear-btn').get(0)) {
            if(isRTL) {
                $(this).closest('.select2-results').append('<div class="clear-btn">محو الكل</div>');
            }else{
                $(this).closest('.select2-results').append('<div class="clear-btn">Clear All</div>');
            }
            $(this).closest('.select2-results').find('.clear-btn').click(function(e){
                $('.select2-container--open').closest('.c-multi-select').find('select').val(null).trigger('change');
                $('.select2-container .select2-results__option.select2-results__option--highlighted').removeClass('select2-results__option--highlighted');
                $('.select2-container .select2-results__option[aria-selected="true"]').attr('aria-selected', 'false');
                select2CheckIfHasSelection();
            });
        }
    });
    select2CheckIfHasSelection();
}

function select2CheckIfHasSelection() {
    $('select').each(function(){
        if(this.value) {
            $(this).closest('.c-multi-select').addClass('has--value');
        }else{
            $(this).closest('.c-multi-select').removeClass('has--value');
        }
    });
    setTimeout(function(){
        $('.select2-results').each(function() {
            if($('.select2-container--open').closest('.c-multi-select').find('select')[0].value){
                $(this).addClass('has--value');
            }else{
                $(this).removeClass('has--value');
            }
        });
    }, 100);
}